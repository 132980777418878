// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dkqtr{display:inline-flex;position:relative;align-items:center;justify-content:space-between;box-sizing:border-box;border:none;outline:none;line-height:inherit;-webkit-user-select:none;-moz-user-select:none;user-select:none;-webkit-appearance:none;overflow:hidden;vertical-align:middle;-webkit-text-decoration:none;-webkit-text-decoration:none;text-decoration:none;cursor:pointer;background:transparent;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;font-family:var(--system-font)}.dkqtr:disabled{cursor:default;opacity:.7;pointer-events:none;border-color:rgba(0,0,0,.37);color:rgba(0,0,0,.37)}.FLuzY{font-weight:600;border-radius:3px}.lN1R3,.FLuzY{font-size:1.15rem;font-size:var(--medium-size);transition-delay:25ms;padding:1.1rem 1.5rem;color:#506cf0;color:var(--primary-color);text-rendering:geometricPrecision;-webkit-font-smoothing:antialiased;--mdc-theme-primary:var(--primary-color)}.lN1R3{font-weight:500;border:1.5px solid #506cf0;border:1.5px solid var(--primary-color);border-radius:3px;text-align:center;justify-content:space-between}.poWfr{position:absolute;top:0;left:0;right:0;bottom:0;background:#262627;background:var(--darken-grey-color);z-index:1;transform:rotate(35deg) scaleX(0);transform-origin:0 0;transition:transform .2s linear}.xHPm7{position:relative;display:block;z-index:2;text-align:center}.xHPm7,.SiKvr{width:100%}.Xm_0I{text-transform:uppercase}.MTULn{margin-left:20px;max-height:17px;opacity:.9}.FLuzY{background:#506cf0;color:#EEF2F7;color:var(--white-color);box-shadow:0 10px 20px 3px rgba(82,104,206,.15);min-width:260px;border-radius:5px;--mdc-theme-primary:var(--white-color)}.FLuzY .poWfr{background:#4e69e1;background:var(--primary-color-2)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `dkqtr`,
	"primary": `FLuzY`,
	"default": `lN1R3`,
	"bg": `poWfr`,
	"content": `xHPm7`,
	"full": `SiKvr`,
	"upper": `Xm_0I`,
	"arrow": `MTULn`
};
export default ___CSS_LOADER_EXPORT___;
