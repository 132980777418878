import React, { useState } from 'react';
import cx from 'classnames';
import withStyles from 'utils/withStyles';
import { Link } from 'react-router-dom';
import ArrowIcon from 'assets/arrow.svg';
import s from './NextButton.css';
import { withOptionalRipple } from 'decorators/withOptionalRipple';

enum ButtonTypes {
  Default = 'Default',
  Primary = 'Primary',
}

type BaseButtonProps = {
  fullWidth?: boolean;
  children?: any;
  upper?: boolean;
  className?: string;
  buttonStyle?: ButtonTypes;
  initRipple?: any;
};

type RealButtonProps = React.DetailedHTMLProps<
React.ButtonHTMLAttributes<HTMLButtonElement>,
HTMLButtonElement
>;

type ButtonLinkTypes = BaseButtonProps & {
  to: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonNotLinkTypes = BaseButtonProps & {
  to?: undefined;
} & RealButtonProps;

type ButtonProps = ButtonLinkTypes | ButtonNotLinkTypes;

const NextButton = (props: ButtonProps) => {
  const [isFocused, setFocused] = useState(false);

  const {
    fullWidth,
    className,
    buttonStyle: type,
    children,
    upper,
    to,
    initRipple,
    ...otherButtonProps
  } = props;

  const classNames = cx(
    s.button,
    className,
    {
      [s.full]: fullWidth,
      [s.default]: type === ButtonTypes.Default,
      [s.upper]: upper,
      [s.primary]: type === ButtonTypes.Primary,
    },
    'mdc-ripple-surface',
    'mdc-ripple-surface--primary',
  );

  if (to) {
    return (
      <Link
        className={classNames}
        to={to}
        innerRef={initRipple}
        {...otherButtonProps as any}
      >
        <span className={s.bg} />
        <span className={s.content}>
          {children}
        </span>
      </Link>
    );
  }

  return (
    <button
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      ref={initRipple}
      className={classNames}
      {...otherButtonProps as RealButtonProps}
    >
      <span className={s.bg} />
      <span className={s.content}>{children}</span>
      <ArrowIcon className={s.arrow} />
    </button>
  );
};

NextButton.defaultProps = {
  initRipple: () => {},
  // This was `type` which was conflict with default HTMLButton prop.
  buttonStyle: ButtonTypes.Default,
};

// todo deal with that later
const decorated: any = withStyles(s)(withOptionalRipple(NextButton) as any);

decorated.Types = ButtonTypes;

export default decorated;
