/* eslint-disable */
/* cuz i dont like it there */
import React, { useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import useWindowSize from "../../../hooks/useWindowSize";

export type ParallaxProps = {
  xFrom: number;
  xTo: number;
  style?: any;

  yFrom: number;
  yTo: number;
  // How x affects y and vice-versa, should be in range from 0 to 1
  affection: number;

  inverse?: boolean;

  children: any;
};

export default function Parallax(props: ParallaxProps) {
  const { xFrom, xTo, yFrom, yTo, affection, children, style } = props;

  const root = useRef<HTMLDivElement>();

  const [width, height] = useWindowSize();

  useEffect(() => {
    const [diffX, diffY] = [xTo - xFrom, yTo - yFrom];

    if ('ontouchstart' in document.documentElement) {
      return () => {};
    }

    const listener = (event: MouseEvent) => {
      if (!root || !root.current) return;

      const [x, y] = [event.pageX, event.pageY];
      const [xDifference, yDifference] = [x / width, y / height];

      const xBaseValue = xFrom + (xDifference * diffX);
      const yBaseValue = yFrom + (yDifference * diffY);

      const xCords = (xBaseValue + (yDifference * affection * diffX));
      const yCords = (yBaseValue + (xDifference * affection * diffY));

      root.current.style.transform = `translate3d(${xCords}px, ${yCords}px, 0)`
    };
    // 90fps is good and enough in most cases
    const throttled = throttle(listener, 1000 / 90);

    document.addEventListener('mousemove', throttled);

    return () => document.removeEventListener('mousemove', throttled);
  }, []);

  return (
    <div style={{ position: 'absolute', left: 0, top: 0, maxWidth: '100vw', maxHeight: '100vh', overflow: 'hidden', width: '100%', height: '100%', zIndex: -1, ...(style || {}) }}>
      <div ref={root} style={{ position: "absolute", left: 0, width: '100%', height: '100%', top: 0, zIndex: -1, maxWidth: '100vw', maxHeight: '100vh' }}>{children}</div>
    </div>
  )
}
