import FiguresIcon from 'assets/figures.svg';
import React, { useMemo } from 'react';
import Parallax from './Parallax';

export default function Figures() {
  const memoed = useMemo(
    () => (
      <Parallax xFrom={10} xTo={40} yFrom={10} yTo={40} affection={0.2}>
        <FiguresIcon />
      </Parallax>
    ),
    [],
  );

  return memoed;
}
